<template>
  <div>
    <a-row :gutter="16">
      <a-col v-if="statusPengajuan !== 'lembur'" :span="24">
        <a-form-item v-if="statusPengajuan === 'cuti'" label="Nama Cuti">
          <a-select :disabled="disabledForm" size="large" class="w-100" :value="dataPengajuan.id_cuti" @change="(e) => handleChange(e, 'id_cuti')">
            <a-select-option v-for="(item, index) in allList" :key="index" :value="item.id">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="statusPengajuan === 'izin'" label="Nama Izin">
          <a-select :disabled="disabledForm" size="large" class="w-100" :value="dataPengajuan.id_izin" @change="(e) => handleChange(e, 'id_izin')">
            <a-select-option v-for="(item, index) in allList" :key="index" :value="item.id">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
     <template v-if="dataPengajuan.listSelected">
        <a-col :span="24">
        <a-form-item label="Regulasi">
          <a-input :disabled="true" v-model="dataPengajuan.listSelected.regulasi" size="large" />
        </a-form-item>
        <a-form-item label="Durasi Maksimal">
          <a-input :disabled="true" :value="`${dataPengajuan.listSelected.durasi_maksimal} ${dataPengajuan.listSelected.tipe_durasi.toUpperCase()}`" size="large" />
        </a-form-item>
        <a-form-item label="Keterangan">
          <a-textarea
            :disabled="true"
            :value="dataPengajuan.listSelected.deskripsi"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
     </template>
      <a-col :span="12">
        <a-form-item label="Tanggal Mulai">
            <a-date-picker
            :disabled="disabledForm"
              :value="statusPengajuan === 'lembur' ? dataPengajuan.waktu_awal : dataPengajuan.tanggal_awal"
              :disabled-date="disabledStartDate"
              :format="`${statusPengajuan === 'lembur' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}`"
              @openChange="handleStartOpenChange"
              @change="(e) => handleChange(e, `${statusPengajuan === 'lembur' ? 'waktu_awal' : 'tanggal_awal'}`)"
              class="w-100"
              size="large"
              placeholder=""
            />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tanggal Selesai">
            <a-date-picker
            :disabled="disabledForm"
            :value="statusPengajuan === 'lembur' ? dataPengajuan.waktu_akhir : dataPengajuan.tanggal_akhir"
            :disabled-date="disabledEndDate"
            :format="`${statusPengajuan === 'lembur' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}`"
            :open="endOpen"
            @openChange="handleEndOpenChange"
            @change="(e) => handleChange(e, `${statusPengajuan === 'lembur' ? 'waktu_akhir' : 'tanggal_akhir'}`)"
            class="w-100"
            size="large"
            placeholder=""
            />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Keterangan Tambahan">
          <a-textarea
            :disabled="disabledForm"
            v-model="dataPengajuan.keterangan_tambahan"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="File Tambahan">
          <div v-if="dataPengajuan.files.length" class="rounded border mb-3">
              <div v-for="item in dataPengajuan.files" :key="item.id" class="d-flex item">
                <div class="ml-3">
                  <a-icon class="text-warning" type="file" style="font-size:20px;" />
                </div>
                <div class="ml-3">
                  <p>{{item.originalname}}</p>
                </div>
                <div class="ml-auto mr-3">
                  <a target="_blank" :href="`${config.apiUrl}/${item.filepath}`"><p class="text-primary text-uppercase">Download</p></a>
                </div>
              </div>
          </div>
          <a-input
            v-if="dataPengajuan.status_pengajuan !== 'terima' && dataPengajuan.status_pengajuan !== 'tolak'"
            class="pt-4 pb-5"
            type="file"
            ref="file"
            multiple
            style="height:48px;"
            @change="selectFile"
          />
        </a-form-item>
      </a-col>
      <a-col v-if="dataPengajuan.status_pengajuan === 'tolak'" :spann="24">
        <a-form-item label="Keterangan Admin">
          <a-textarea
            :disabled="disabledForm"
            v-model="dataPengajuan.keterangan_admin"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import config from '@/config'
const daftarTipeCuti = [
  {
    nama: 'cuti',
  },
  {
    nama: 'lain-lain',
  },
]
const daftarRegulasi = [
  {
    nama: 'Dibayar',
  },
  {
    nama: 'Tidak Dibayar',
  },
]
export default {
  props: {
    dataPengajuan: {
      type: Object,
      required: true,
    },
    allList: {
      type: Array,
      required: true,
    },
    disabledForm: {
      type: Boolean,
      required: true,
    },
    statusPengajuan: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      config,
      daftarTipeCuti,
      daftarRegulasi,
      endOpen: false,
    }
  },
  methods: {
    selectFile() {
      const files = this.$refs.file.$refs.input.files
      const mappedFiles = Object.keys(files).map((key) => files[key])
      this.handleChange(mappedFiles, 'files_upload')
    },
    disabledStartDate(startValue) {
      const endValue = this.dataPengajuan.tanggal_akhir
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.dataPengajuan.tanggal_awal
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style lang="scss">
@import '@/components/mixins.scss';

.item {
  border-bottom: 1px solid $gray-2;
  padding: 5px 0;

  &:last-child {
    border-bottom: none;
  }
}

</style>
